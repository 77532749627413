import {
  addOperation,
  adminCreateUser,
  allowSpecialLandingPage,
  allowSpecialProgram,
  allowSpecialProgramOption,
  allowSpecialProject,
  deleteFixedPayment,
  denySpecialLandingPage,
  denySpecialProgram,
  denySpecialProgramOption,
  denySpecialProject,
  getAccruals,
  getAdminProjects,
  getAuthLog,
  getBonuses,
  getFixedPayments,
  getGamerMultiples,
  getMultiples,
  getNullingLogs,
  getPayouts,
  getProjects,
  getRoleLogs,
  getRolesList,
  getSpecialLandingPages,
  getSpecialProgramOptions,
  getSpecialProgramOptionsList,
  getSpecialPrograms,
  getSpecialProjects,
  getStatusLogs,
  getUsersList,
  getUsersProfile,
  getUserTypes,
  getWalletLogs,
  updateProfile,
} from "./api";
import {
  AccrualsRequest,
  AdminCreateUserProjectsQuery,
  AuthLogRequest,
  BonusesRequest,
  GamerMultiplesRequest,
  MultiplesRequest,
  NullingLogsRequest,
  PayoutsRequest,
  RoleLogsRequest,
  SpecialLandingPagesRequest,
  SpecialProgramOptionsListRequest,
  SpecialProgramOptionsRequest,
  SpecialProgramsRequest,
  SpecialProjectsRequest,
  StatusLogsRequest,
  SUCCESS_STATUS_MESSAGE,
  UsersListRequest,
  UsersProfileRequest,
  WalletLogsRequest,
} from "./constants";
import {
  MutationOptions,
  queryCache,
  useMutation,
  useQuery,
} from "react-query";
import { notify, notifyMap } from "../../system/utils/notification";
import { _t } from "../Common/components/InjectIntlContext";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../system/constants/constantsUrl";
import { getItem } from "../../system/helpers/localstorageHelper";

const UsersList = "users-list";
export const UsersProfileQuery = "users-profile";
export const UsersProfileAccrualsQuery = "users-profile-accruals";
export const UsersProfilePayoutsQuery = "users-profile-payouts";
export const UsersProfileAuthLogQuery = "users-profile-auth-log";
export const UsersProfileMultiplesQuery = "users-profile-multiples";
export const UsersProfileGamerMultiplesQuery = "users-profile-gamer-multiples";
export const UsersProfileBonusesQuery = "users-profile-bonuses";
export const UsersProfileWalletLogsQuery = "users-profile-wallet-logs";
export const UsersProfileStatusLogsQuery = "users-profile-status-logs";
export const UsersProfileRoleLogsQuery = "users-profile-role-logs";
export const UsersProfileNullingLogsQuery = "users-profile-nulling-logs";
export const UsersProfileProjectsQuery = "users-profile-projects";
export const UsersProfileSpecialProjectsQuery =
  "users-profile-special-projects";
export const UsersProfileSpecialProjectsAllowQuery =
  "users-profile-special-projects-allow";
export const UsersProfileSpecialProjectsDenyQuery =
  "users-profile-special-projects-deny";
export const UsersProfileSpecialProgramsQuery =
  "users-profile-special-programs";
export const UsersProfileSpecialProgramsAllowQuery =
  "users-profile-special-programs-allow";
export const UsersProfileSpecialProgramsDenyQuery =
  "users-profile-special-programs-deny";
export const UsersProfileSpecialLandingPagesQuery =
  "users-profile-special-landing-pages";
export const UsersProfileSpecialLandingPagesAllowQuery =
  "users-profile-special-landing-pages-allow";
export const UsersProfileSpecialLandingPagesDenyQuery =
  "users-profile-special-landing-pages-deny";
export const UsersProfileSpecialProgramOptionsQuery =
  "users-profile-special-program-options";
export const UsersProfileSpecialProgramOptionsListQuery =
  "users-profile-special-program-options-list";
export const UsersProfileRolesListQuery = "users-profile-roles-list";

export const useUsersList = (params: UsersListRequest) => {
  return useQuery([UsersList, params], (k, params) => getUsersList(params));
};

export const useUsersProfile = (params: UsersProfileRequest) => {
  return useQuery([UsersProfileQuery, params], (k, params) =>
    getUsersProfile(params)
  );
};

export const useUpdateProfile = (options: MutationOptions<any, any> = {}) => {
  const successfullyUpdated = _t("successfully_updated");
  const errorMessage = _t("profile_update_error_message");

  return useMutation(updateProfile, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileQuery);
      if (data.status === SUCCESS_STATUS_MESSAGE) {
        notify("success", successfullyUpdated);
      } else {
        notify("error", data.message);
      }
    },
    onError: (error) => {
      notify("error", errorMessage);
    },
  });
};

export const useAccruals = (params: AccrualsRequest) => {
  return useQuery([UsersProfileAccrualsQuery, params], (k, params) =>
    getAccruals(params)
  );
};

export const usePayouts = (params: PayoutsRequest) => {
  return useQuery([UsersProfilePayoutsQuery, params], (k, params) =>
    getPayouts(params)
  );
};

export const useAuthLog = (params: AuthLogRequest) => {
  return useQuery([UsersProfileAuthLogQuery, params], (k, params) =>
    getAuthLog(params)
  );
};

export const useMultiples = (params: MultiplesRequest) => {
  return useQuery([UsersProfileMultiplesQuery, params], (k, params) =>
    getMultiples(params)
  );
};

export const useGamerMultiples = (params: GamerMultiplesRequest) => {
  return useQuery([UsersProfileGamerMultiplesQuery, params], (k, params) =>
    getGamerMultiples(params)
  );
};

export const useBonuses = (params: BonusesRequest) => {
  return useQuery([UsersProfileBonusesQuery, params], (k, params) =>
    getBonuses(params)
  );
};

export const useWalletLogs = (params: WalletLogsRequest) => {
  return useQuery([UsersProfileWalletLogsQuery, params], (k, params) =>
    getWalletLogs(params)
  );
};

export const useStatusLogs = (params: StatusLogsRequest) => {
  return useQuery([UsersProfileStatusLogsQuery, params], (k, params) =>
    getStatusLogs(params)
  );
};

export const useRoleLogs = (params: RoleLogsRequest) => {
  return useQuery([UsersProfileRoleLogsQuery, params], (k, params) =>
    getRoleLogs(params)
  );
};

export const useNullingLogs = (params: NullingLogsRequest) => {
  return useQuery([UsersProfileNullingLogsQuery, params], (k, params) =>
    getNullingLogs(params)
  );
};

export const useProjects = (params = {}) => {
  return useQuery([UsersProfileProjectsQuery, params], (k, params) =>
    getProjects()
  );
};

export const useAddOperation = () => {
  const successfullyAdded = _t("successfully_added");
  const errorMessage = _t("error_message");

  return useMutation(addOperation, {
    onSuccess: (data, variables) => {
      notify("success", successfullyAdded);
    },
    onError: () => {
      notify("error", errorMessage);
    },
  });
};

export const useSpecialProjects = (params: SpecialProjectsRequest) => {
  return useQuery([UsersProfileSpecialProjectsQuery, params], (k, params) =>
    getSpecialProjects(params)
  );
};

export const useAllowSpecialProject = () => {
  const allowedMessage = _t("allowed");
  return useMutation(allowSpecialProject, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialProjectsQuery);
      notify("success", allowedMessage);
    },
  });
};

export const useDenySpecialProject = () => {
  const deniedMessage = _t("denied");
  return useMutation(denySpecialProject, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialProjectsQuery);
      notify("success", deniedMessage);
    },
  });
};

export const useSpecialPrograms = (params: SpecialProgramsRequest) => {
  return useQuery([UsersProfileSpecialProgramsQuery, params], (k, params) =>
    getSpecialPrograms(params)
  );
};

export const useAllowSpecialProgram = () => {
  const allowedMessage = _t("allowed");
  return useMutation(allowSpecialProgram, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialProgramsQuery);
      notify("success", allowedMessage);
    },
  });
};

export const useDenySpecialProgram = () => {
  const deniedMessage = _t("denied");
  return useMutation(denySpecialProgram, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialProgramsQuery);
      notify("success", deniedMessage);
    },
  });
};

export const useSpecialLandingPages = (params: SpecialLandingPagesRequest) => {
  return useQuery([UsersProfileSpecialLandingPagesQuery, params], (k, params) =>
    getSpecialLandingPages(params)
  );
};

export const useAllowSpecialLandingPage = () => {
  const allowedMessage = _t("allowed");
  return useMutation(allowSpecialLandingPage, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialLandingPagesQuery);
      notify("success", allowedMessage);
    },
  });
};

export const useDenySpecialLandingPage = () => {
  const deniedMessage = _t("denied");
  return useMutation(denySpecialLandingPage, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialLandingPagesQuery);
      notify("success", deniedMessage);
    },
  });
};

export const useAdminProjects = () => {
  return useQuery([AdminCreateUserProjectsQuery], getAdminProjects);
};

export const useAdminCreateUser = (options: MutationOptions<any, any> = {}) => {
  const successfullyCreated = _t("successfully_created");
  let history = useHistory();
  return useMutation(adminCreateUser, {
    onSuccess: (data, variables) => {
      if (data.data.status === SUCCESS_STATUS_MESSAGE) {
        notify("success", successfullyCreated);
        history.push(
          APP_ROUTES.manage.user_profile.replace(":partnerId", data.data.user)
        );
      } else {
        notifyMap("error", data.data.messages);
      }
    },
  });
};

export const useSpecialProgramOptions = (
  params: SpecialProgramOptionsRequest
) => {
  return useQuery(
    [UsersProfileSpecialProgramOptionsQuery, params],
    (k, params) => getSpecialProgramOptions(params)
  );
};

export const useSpecialProgramOptionsList = (
  params: SpecialProgramOptionsListRequest
) => {
  return useQuery(
    [UsersProfileSpecialProgramOptionsListQuery, params],
    (k, params) => getSpecialProgramOptionsList(params)
  );
};

export const useDenySpecialProgramOption = () => {
  const deniedMessage = _t("denied");
  return useMutation(denySpecialProgramOption, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialProgramOptionsQuery);
      notify("success", deniedMessage);
    },
  });
};

export const useAllowSpecialProgramOption = () => {
  const allowedMessage = _t("allowed");
  return useMutation(allowSpecialProgramOption, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(UsersProfileSpecialProgramOptionsQuery);
      notify("success", allowedMessage);
    },
  });
};

export const useGetRolesList = () => {
  return useQuery([UsersProfileRolesListQuery], () => getRolesList());
};
export const useGetFixedPayments = (params) => {
  return useQuery([params, "get-fixed-payments"], () =>
    getFixedPayments(params)
  );
};
export const usePostDeleteFixedPayment = () => {
  return useMutation((id) => deleteFixedPayment(id));
};

export const useGetUserTypes = () => {
  return useQuery(["user-profile-types"], () => getUserTypes(), {
    enabled: getItem("role") === "admin",
  });
};
