import React from "react";
import { useGetFixedPayments, usePostDeleteFixedPayment } from "../../apiHooks";
import { Button, Card, Divider, Table } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";
import { CurrencyLabel } from "../../../Common/components";

const FixedPayments = ({ user_id }) => {
  const { data } = useGetFixedPayments({ user_id });
  const [mutate, { isLoading }] = usePostDeleteFixedPayment();
  // data?.data.length > 0
  return (
    <>
      {data?.data && (
        <>
          <Divider />
          <Card>
            <Table
              columns={[
                {
                  title: _t("program_name"),
                  dataIndex: "program_name",
                },
                {
                  title: _t("date"),
                  dataIndex: "date",
                },
                {
                  title: _t("period"),
                  dataIndex: "period",
                },
                {
                  title: _t("fixed_payment"),
                  dataIndex: "fixed_payment",
                  render: (v) => <CurrencyLabel value={v} />,
                },
                {
                  render: (v, r) => (
                    <Button
                      htmlType="button"
                      type="ghost"
                      disabled={isLoading}
                      onClick={() => mutate(r.program_id)}
                    >
                      Delete
                    </Button>
                  ),
                },
              ]}
              pagination={false}
              dataSource={data?.data}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default FixedPayments;
